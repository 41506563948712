import absa from '../images/absa.png';
import ceta from '../images/ceta.png';
import chbasa from '../images/chbasa.png';
import nsf from '../images/nsf.png';

const Certifications = () => {
    return (
        <div id='affiliations' class='certifications-container'>
            <h1>Affiliations</h1>
            <div class='certifications-icons-container'>
                <img src={absa} />
                <img src={ceta} />
                <img src={chbasa} />
                <img src={nsf} />
            </div>
        </div>
    )
}

export default Certifications;
