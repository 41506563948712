import { Link } from 'react-router-dom'

import logo from '../images/logo.png'

const Nav = () => {
    return (
        <div className='nav-container'>
            <div className='nav-logo'>
                <img src={logo} />
            </div>
            <div className='nav-links'>
                <div style={{position: 'relative'}}><Link to='/affiliations'>Affiliations</Link></div>
                <div style={{position: 'relative'}}><Link to='/services'>Services</Link></div>
                <div style={{position: 'relative'}}><Link to='/contact'>Contact</Link></div>
            </div>
        </div>
    )
}

export default Nav;
