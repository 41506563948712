import {useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import Home from './components/Home';
import Nav from './components/Nav';
import Certifications from './components/Certifications';
import Services from './components/Services';
import Contact from './components/Contact';
import './App.css';

function App() {
  const { pathname, key } = useLocation();

  useEffect(() => {
    let path = pathname.slice(1)

    if(path === '' || path === 'home') {
      window.scrollTo({top: 0, behavior: 'smooth'})
    } else {
      const target = document.getElementById(path);
      target.scrollIntoView({behavior: 'smooth'})
    }

  }, [pathname, key])

  return (
    <div className="app-container">
      <Nav />
      <Home />
      <Certifications />
      <Services />
      <Contact />
    </div>
  );
}

export default App;
