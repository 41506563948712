import { useRef } from 'react';
import emailjs from 'emailjs-com'

import { IoLocationOutline as LocationIcon } from "react-icons/io5";
import { IoCallOutline as PhoneIcon } from "react-icons/io5";
import { IoMailOutline as MailIcon} from "react-icons/io5";

const Contact = () => {
    const form = useRef();

    const formResponse = (success, event) => {
        const submitBtn = document.getElementById('submit-btn')
        if(success) {
            submitBtn.classList.add('contact-form-success')
            submitBtn.innerHTML = 'message sent!'
            setTimeout(() => {
                submitBtn.classList.remove('contact-form-success')
                submitBtn.innerHTML = 'send message'
            }, 4000)
        } else {
            submitBtn.classList.add('contact-form-error')
            submitBtn.innerHTML = 'message failed'
            setTimeout(() => {
                submitBtn.classList.remove('contact-form-error')
                submitBtn.innerHTML = 'send message'
            }, 4000)

        }
    }

    const submitEmailJs = (e) => {
        e.preventDefault()

        emailjs.init("user_RoBiVR1EO4QvmeTDjxGN7")
        emailjs.sendForm('service_ingbnhf','template_whqlbtf', '#contact-form')
            .then((result) => {
                console.log(result);
                if(result.status === 200) {
                    e.target.reset();
                    formResponse(true, e);
                }
            }, (error) => {
                formResponse(false, e);
            });
    }

    return (
        <div id='contact' className='contact-container'>
            <h1>Contact</h1>
            <div className='contact-icons'>
                <div className='contact-icon-badge'>
                    <LocationIcon />
                    <h3 className='contact-location-h3'>512 N. Crain Hwy Ste. 30<br />Glen Burnie, MD</h3>
                </div>
                <div className='contact-icon-badge'>
                    <PhoneIcon />
                    <h3 className='contact-phone-h3'>443.306.1573</h3>
                </div>
                <div className='contact-icon-badge'>
                    <MailIcon />
                    <h3 className='contact-mail-h3'>kayuniversal@gmail.com</h3>
                </div>
            </div>
            <div className='contact-form'>
                <h1>Contact Form</h1>
                <form id='contact-form' ref={form} onSubmit={submitEmailJs}>
                    <label>Name</label>
                    <input name='name' type='text' />
                    <label>Email</label>
                    <input name='email' type='email' />
                    <label>Message</label>
                    <textarea name='message' style={{resize: 'none'}} rows={4} />
                    <button id='submit-btn' className='contact-form-btn' type='submit'>Send</button>
                </form>
            </div>
        </div>
    )
}

export default Contact;
