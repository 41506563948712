import { Link } from 'react-router-dom';

import balometerPic from '../images/balometer.jpg';

const Home = () => {
    return (
        <div className='home-container'>
            <div class='home-left'>
                <h2>For more than 25 years, Universal Safety Associates has provided the highest caliber certification services to the pharmaceutical, hospital and research industries, including some of the most prestigious facilities throughout Maryland.</h2>
                {/* <h2>More than 25 years experience working with clients to deliver quality solutions and unmatched customer support.</h2> */}
                <h2>Our experts in the field provide consultation, testing, monitoring, maintenance and repairs. Final comprehensive data reports are provided at the completion of every project.</h2>
                <Link className='home-contact-btn' to='/contact'>Book a Consultation</Link>
            </div>
            <div className='home-right'>
                <img src={balometerPic} />
                <div className='home-right-imgFade' />
            </div>
        </div>
    )
}

export default Home;
