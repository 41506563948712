const Services = () => {
    return (
        <div id='services' className='services-container'>
            <h1>Services</h1>
            <p className='services-summary-p'>Universal Safety Associates, Inc. provides services necessary to ensure that contolled environments meet the latest standards pursuant to the scientific and technological advancements in the pharmaceutical and biotechnology industries.</p>
            <div className='services-subsection-container'>
                <div className='services-subsection'>
                    <h2 className='services-subsection-break'>Clean Room Testing</h2>
                    <h3>ISO 14644 Specifications</h3>
                    <h3>Viable & Non-viable Air Sampling</h3>
                    <h3>Aerosol Challenge HEPA filters</h3>
                    <h3>Pressure Differential</h3>
                    <h3>Airflow Testing</h3>
                    <h3>Ergonomic Survey</h3>
                </div>
                <div className='services-subsection'>
                    <h2 className='services-subsection-break'>Pharmacy Testing & Certification</h2>
                    <h3>USP 797/800 Standards</h3>
                    <h3>Laminar Flow Devices</h3>
                    <h3>Biological Safety Cabinets</h3>
                    <h3>Viable & Non-viable Air Sampling</h3>
                    <h3>Aerosol Challenge HEPA filters</h3>
                    <h3>Pressure Differential</h3>
                    <h3>Airflow Testing</h3>
                    <h3>Ergonomic Survey</h3>
                </div>
                <div className='services-subsection'>
                    <h2 className='services-subsection-break'>Biological Safety Cabinets</h2>
                    <h3>Testing</h3>
                    <h3>Decontamination</h3>
                    <h3>Certification per NSF49 Standard</h3>
                    <h3>Troubleshooting & Repairs</h3>
                </div>
                <div className='services-subsection'>
                    <h2 className='services-subsection-break'>Laminar Flow Hood/Clean Bench</h2>
                    <h3>Testing</h3>
                    <h3>Certification</h3>
                    <h3>Troubleshooting & Repairs</h3>
                </div>
                <div className='services-subsection'>
                    <h2 className='services-subsection-break'>Chemical Fumehood</h2>
                    <h3>Testing</h3>
                    <h3>Certification per:</h3>
                    <h4>AIHA/ANSI Lab Ventilation Standard</h4>
                    <h4>SEFA Lab Fumehood Required Practices</h4>
                    <h3>Troubleshooting & Repairs</h3>
                </div>
            </div>
        </div>
    )
}

export default Services;
